import React, { useEffect } from "react";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { useStyletron } from "baseui";
import { Alert } from "baseui/icon";

import { validate as validateEmail } from "email-validator";
function Negative() {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        alignItems: "center",
        paddingRight: theme.sizing.scale500,
        color: theme.colors.negative400,
      })}
    >
      <Alert size="20px" />
    </div>
  );
}
export default function EmailInput({ isValid, setIsValid, email, setEmail }) {
  const [isVisited, setIsVisited] = React.useState(false);
  const shouldShowError = !isValid && isVisited;
  const onChange = ({ target: { value } }) => {
    setIsValid(validateEmail(value));
    setEmail(value);
  };

  useEffect(() => {
    setIsValid(validateEmail(email));
  }, []);

  return (
    <FormControl
      label="Электронная почта"
      error={shouldShowError ? "Укажите верный email, пожалуйста" : null}
    >
      <Input
        id="email-input-id"
        value={email}
        onChange={onChange}
        onBlur={() => setIsVisited(true)}
        error={shouldShowError}
        overrides={shouldShowError ? { After: Negative } : {}}
        type="email"
        required
      />
    </FormControl>
  );
}
