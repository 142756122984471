import React, { useEffect } from "react";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { useStyletron } from "baseui";
import { Alert } from "baseui/icon";

function Negative() {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        alignItems: "center",
        paddingRight: theme.sizing.scale500,
        color: theme.colors.negative400,
      })}
    >
      <Alert size="20px" />
    </div>
  );
}

const toNumber = (value) => {
  return parseInt(value.replace(/[^\d]+/g, "")) || "";
};

const validatePhone = (value) => {
  return value.toString().length === 10;
};

export default function PhoneInput({
  isValid,
  setIsValid,
  phoneNumber,
  setPhoneNumber,
}) {
  const [isVisited, setIsVisited] = React.useState(false);
  const shouldShowError = !isValid && isVisited;
  const onChange = ({ target: { value } }) => {
    const cleanValue = toNumber(value);
    setIsValid(validatePhone(cleanValue));
    setPhoneNumber(cleanValue);
  };

  useEffect(() => {
    setIsValid(validatePhone(phoneNumber));
  }, []);

  return (
    <FormControl
      label="Телефон"
      error={
        shouldShowError ? "Укажите верный номер телефона, пожалуйста" : null
      }
    >
      <Input
        startEnhancer="+7"
        id="phone-input-id"
        value={phoneNumber}
        onChange={onChange}
        onBlur={() => setIsVisited(true)}
        error={shouldShowError}
        overrides={shouldShowError ? { After: Negative } : {}}
        type="phone"
        required
      />
    </FormControl>
  );
}
