import React from "react";

import { useStyletron } from "baseui";
import { HeadingLevel } from "baseui/heading";

import Page from "../../components/internal-page";
import ApplyProgress from "../../components/apply-progress";

const Online = () => {
  const [css] = useStyletron();
  const mt5 = css({
    marginTop: `5px`,
  });
  return (
    <Page title="Заполнить форму" path="/apply/online" parent="apply">
      <HeadingLevel>
        <ApplyProgress />
      </HeadingLevel>
    </Page>
  );
};

export default Online;
