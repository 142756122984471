import React, { useState, useEffect, useRef } from "react";

import { FormControl } from "baseui/form-control";
import { Select, TYPE } from "baseui/select";
import { Paragraph3, Label3 } from "baseui/typography";
import { Card, StyledBody } from "baseui/card";

import { useUserData } from "../hooks/useUserData";

function debounce(callback, time) {
  let interval;
  return (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
}

const Finder = () => {
  const { user, setUser } = useUserData();
  const [query, setQuery] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState([]);

  const debounceSearch = useRef(
    debounce((query) => {
      const url = process.env.GATSBY_DADATA_SUGGESTION_API_URL;
      const token = process.env.GATSBY_DADATA_API_KEY;
      const opts = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Token " + token,
        },
        body: JSON.stringify({
          query: query,
          count: 20,
          locations: [{ kladr_id: "29" }],
          status: ["ACTIVE"],
        }),
      };

      (async () => {
        const response = await fetch(url, opts);
        const result = await response.json();
        setIsSearching(false);
        setOptions(
          result.suggestions.map((entry) => {
            return {
              company: `${entry.value}, ИНН ${entry.data.inn}, ${
                entry.data.address?.value || ""
              }`,
              inn: entry.data.inn,
              value: entry.value,
              address: entry.data.address?.value,
              okved: entry.data.okved,
              registrationDate: entry.data.state.registration_date,
              type: entry.data.type,
            };
          })
        );
      })();
    }, 500)
  );

  useEffect(() => {
    if (query.length > 0) {
      setIsSearching(true);
      debounceSearch.current(query);
    }
  }, [query]);

  return (
    <>
      <FormControl
        label="Найдите и выберите компанию или предпринимателя"
        caption="Можно искать по ИНН, имени предпринимателя, названию компании или имени руководителя, городу, району"
      >
        <Select
          options={options}
          labelKey="company"
          valueKey="inn"
          placeholder={"Начинайте искать"}
          maxDropdownHeight="300px"
          type={TYPE.search}
          onInputChange={(e) => {
            setQuery(e.target.value);
          }}
          onChange={({ value }) => setUser(value)}
          value={user}
          isLoading={isSearching}
          noResultsMsg={isSearching ? "Идет поиск..." : "Ничего не найдено"}
          onBlurResetsInput={false}
          filterOptions={false}
          escapeClearsValue={false}
        />
      </FormControl>
      {user && user[0] && (
        <Card>
          <StyledBody>
            <Label3>{user[0].value}</Label3>
            <Paragraph3>Адрес: {user[0].address}</Paragraph3>
            <Paragraph3>ИНН: {user[0].inn}</Paragraph3>
            <Paragraph3>Основной ОКВЭД: {user[0].okved}</Paragraph3>
            <Paragraph3>
              Дата регистрации:{" "}
              {new Date(user[0].registrationDate).toLocaleDateString("ru-RU")}
            </Paragraph3>
          </StyledBody>
        </Card>
      )}
    </>
  );
};

export default Finder;
