import React, { useEffect, useState } from "react";
import { ProgressSteps, NumberedStep } from "baseui/progress-steps";
import { Button, KIND } from "baseui/button";
import { Card, StyledBody } from "baseui/card";
import { Paragraph2, Paragraph3, Label3 } from "baseui/typography";
import { Spinner } from "baseui/spinner";

import { useStyletron } from "baseui";

import CompanyFinder from "./sme-finder";

import { FormControl } from "baseui/form-control";
import PhoneInput from "./phone-input";
import EmailInput from "./email-input";

import { Select } from "baseui/select";
import { PROGRAMS } from "../data/programs";
import Calc from "./calc";

import { useUserData } from "../hooks/useUserData";

const SpacedButton = (props) => {
  return (
    <Button
      {...props}
      kind={KIND.secondary}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            marginLeft: $theme.sizing.scale200,
            marginRight: $theme.sizing.scale200,
            marginTop: $theme.sizing.scale800,
          }),
        },
      }}
    />
  );
};
const ApplyProgress = () => {
  const { user, setUser } = useUserData();
  const [current, setCurrent] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [resultMsg, setResultMgs] = useState(false);

  const selectOptions = PROGRAMS.filter(
    (program) => program.name !== "«Самозанятые граждане»"
  ).map((program) => ({
    id: program.id,
    label: `${program.name}`,
  }));
  const [selectValue, setSelectValue] = useState([]);
  const [program, setProgram] = useState(
    selectValue.length > 0 ? PROGRAMS[selectValue[0].id] : []
  );
  const [amount, setAmount] = useState([program.maxAmount || 500_000]);
  const [term, setTerm] = useState([program.loanTerm || 24]);
  const [gracePeriod, setGracePeriod] = useState([0]);

  // if user has already applied
  const isApplied = user && user[0]?.isApplied ? user[0]?.isApplied : false;

  const [email, setEmail] = useState(
    user && user[0]?.email ? user[0]?.email : ""
  );
  const [isValidEmail, setIsValidEmail] = useState(false);

  const [isValidPhone, setIsValidPhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    user && user[0]?.phone ? user[0]?.phone : ""
  );
  const [css, theme] = useStyletron();

  useEffect(() => {
    if (isApplied) {
      setCurrent(4);
      setResultMgs(`Вы уже подали обращение от ${user[0].value}.`);
    }
  }, []);

  useEffect(() => {
    if (!user[0] || !user[0].phone) {
      setPhoneNumber("");
    }
    if (!user[0] || !user[0].email) {
      setEmail("");
    }
  }, [user]);

  const fetchData = (name, email, sme, html) => {
    const host = "https://cmf.vercel.app";
    const url = host + "/api/send-email";

    const payload = {
      name: name,
      email: email,
      sme: sme,
      html: html,
    };

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // show spinner
    setCurrent(4);
    setIsSending(true);

    const { value, inn, address, okved, registrationDate, email, phone } =
      user[0];
    const html = `<h2>${value}</h2>
  <p>ИНН: ${inn}</p>
  <p>Адрес: ${address}</p>
  <p>Основной ОКВЭД: ${okved}</p>
  <p>Дата регистрации: ${new Date(registrationDate).toLocaleDateString(
    "ru-RU"
  )}</p>
  <h3>Программа ${program?.name}</h3>
  <p>Сумма: ${amount[0].toLocaleString("ru-RU")} руб.</p>
  <p>Срок: ${term[0]} мес.</p>
  <p>Льготный период: ${gracePeriod[0]} мес.</p>
  <h3>Контактные данные</h3>
  <p>Телефон: +7 ${phone}</p>
  <p>Электронная почта: ${email}</p>
  `;

    fetchData("Эксперт", "expert@cmf29.ru", value, html)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // turn off spinner
        setIsSending(false);
        if (user[0]) {
          setUser([{ ...user[0], isApplied: true }]);
        }
        setResultMgs(
          `Заявка успешно отправлена. Финансовый эксперт Фонда свяжется с вами.`
        );
      })
      .catch((error) => {
        setIsSending(false);
        setUser([{ ...user[0], isApplied: false }]);
        console.log(`На сервере произошла ошибка: ${error}`);
        setResultMgs(`Что-то пошло не так. Попробуйте позже.`);
      });
  };

  return (
    <ProgressSteps current={current}>
      <NumberedStep title="Укажите наименование юридического лица или индивидуального предпринимателя">
        <CompanyFinder />
        <SpacedButton disabled>Назад</SpacedButton>
        <SpacedButton onClick={() => setCurrent(1)} disabled={user && !user[0]}>
          Далее
        </SpacedButton>
      </NumberedStep>
      <NumberedStep title="Укажите программу и сумму">
        <FormControl
          label="Выберите программу микрокредитования"
          caption={
            selectValue.length > 0 &&
            `${program.interestRate.toLocaleString("ru-RU")}% годовых, до ${(
              program.maxAmount || 5_000_000
            ).toLocaleString("ru-RU")} руб., срок до ${
              program.loanTerm || 24
            } мес.`
          }
        >
          <Select
            clearable={false}
            deleteRemoves={false}
            options={selectOptions}
            value={selectValue}
            required
            searchable={false}
            placeholder="Выберите программу"
            onChange={(params) => {
              setSelectValue(params.value);
              setProgram(PROGRAMS[params.option.id]);
            }}
          />
        </FormControl>

        {selectValue.length > 0 && (
          <Calc
            programId={program.id}
            externalAmount={amount}
            externalTerm={term}
            externalGracePeriod={gracePeriod}
            setExternalAmount={setAmount}
            setExternalTerm={setTerm}
            setExternalGracePeriod={setGracePeriod}
          />
        )}
        <SpacedButton onClick={() => setCurrent(0)}>Назад</SpacedButton>
        <SpacedButton
          onClick={() => setCurrent(2)}
          disabled={selectValue.length === 0}
        >
          Далее
        </SpacedButton>
      </NumberedStep>
      <NumberedStep title="Укажите контактные данные">
        <PhoneInput
          isValid={isValidPhone}
          setIsValid={setIsValidPhone}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
        <EmailInput
          isValid={isValidEmail}
          setIsValid={setIsValidEmail}
          email={email}
          setEmail={setEmail}
        />
        <SpacedButton onClick={() => setCurrent(1)}>Назад</SpacedButton>
        <SpacedButton
          onClick={() => {
            if (user && user[0]) {
              setUser([{ ...user[0], phone: phoneNumber, email: email }]);
            }
            setCurrent(3);
          }}
          disabled={!isValidEmail || !isValidPhone}
        >
          Далее
        </SpacedButton>
      </NumberedStep>
      <NumberedStep title="Проверьте информацию и отправьте">
        <form onSubmit={(e) => handleSubmit(e, user)}>
          {user && user[0] ? (
            <Card>
              <StyledBody>
                <Label3>{user[0].value}</Label3>
                <Paragraph3>Адрес: {user[0].address}</Paragraph3>
                <Paragraph3>ИНН: {user[0].inn}</Paragraph3>
                <Paragraph3>Основной ОКВЭД: {user[0].okved}</Paragraph3>
                <Paragraph3>
                  Дата регистрации:{" "}
                  {new Date(user[0].registrationDate).toLocaleDateString(
                    "ru-RU"
                  )}
                </Paragraph3>
                <Label3>Программа {program?.name}</Label3>
                <Paragraph3>
                  Сумма:{" "}
                  {amount.length > 0 &&
                    `${amount[0].toLocaleString("ru-RU")} руб.`}
                </Paragraph3>
                <Paragraph3>Срок: {term.length > 0 && term[0]} мес.</Paragraph3>
                <Paragraph3>
                  Льготный период: {gracePeriod.length > 0 && gracePeriod[0]}{" "}
                  мес.
                </Paragraph3>
                <Paragraph3>Электронная почта: {user[0]?.email}</Paragraph3>
                <Paragraph3>Телефон: +7 {user[0].phone}</Paragraph3>
              </StyledBody>
            </Card>
          ) : null}
          <SpacedButton onClick={() => setCurrent(2)}>Назад</SpacedButton>
          <Button
            type="submit"
            overrides={{
              BaseButton: {
                style: ({ $theme }) => ({
                  marginLeft: $theme.sizing.scale200,
                  marginRight: $theme.sizing.scale200,
                  marginTop: $theme.sizing.scale800,
                }),
              },
            }}
          >
            Отправить
          </Button>
        </form>
      </NumberedStep>
      <NumberedStep title="Результат">
        {isSending ? (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
            })}
          >
            <Spinner $size={30} />
            <Paragraph2 paddingLeft={"10px"}>Идет отправка...</Paragraph2>
          </div>
        ) : (
          <>
            <Paragraph2>{resultMsg}</Paragraph2>
            <SpacedButton
              onClick={() => {
                setUser([]);
                setCurrent(0);
                
                //  default values for calc
                setSelectValue([]);
                setAmount([500_000]);
                setTerm([24]);
                setGracePeriod([0]);
              }}
            >
              Подать новое обращение
            </SpacedButton>
          </>
        )}
      </NumberedStep>
    </ProgressSteps>
  );
};
export default ApplyProgress;
